import React from "react";
import { graphql } from "gatsby";
import Layout from "~/components/layout";
import SEO from "~/components/seo";

const TermsPage = ({ data, pageContext }) => {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  const paths = pageContext.paths;

  return (
    <Layout paths={paths}>
      <SEO
        title={frontmatter.title}
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        location={paths}
      />

      <div className="p-2 md:p-4 md:py-6  border-b border-gray-200">
        <div className="md:mr-auto md:ml-auto lg:max-w-6xl">
          <h1>{frontmatter.title}</h1>
          <hr className="border-t border-gray-400" />
          <div
            className="document text-justify"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;

export default TermsPage;
